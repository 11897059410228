<template>
  <div id="books_index_zh">
    <div class="muludiv">
<!--      @click="getPageList(item, index)"-->
      <div class="aqt_zhengzhuang" v-for="(item, index) in infosData" :key="index"
           v-html="U2M(item.titlelang)">
      </div>
    </div>
    <NoData style="height: 100px;margin: auto;padding: 10px;line-height: 70px" v-if="isLoading && !infosData.length"></NoData>
  </div>
</template>

<script>
export default {
  components: {
    NoData: (resolve) => {
      require(["@/components/NoData.vue"], resolve);
    },
  },
  data() {
    return {
      isLoading: false,
      getInfo: false,
      infosData: [],
      data: {
        directory_id: "",
        book_id: "",
        type: "1",
      },
      content_id: null,
      indexList: [],
      showList: false,
      pageDate: {
        total: 0,
        per_page: 0,
        current_page: 1,
      },
      infosDataOld: [],
    };
  },
  methods: {
    /*****************************************************************
     *
     * 获取详情数据
     *
     *****************************************************************/
    infoData() {
      const _this = this;
      _this.showToastMask(true);
      let cond = {
        annexboolean: _this.annexboolean,
        book_id: _this.$route.query.id,
        type: 1
      }
      _this.isLoading = false
      _this.$http.get(`api/web/v2/BookDirectory/index`, {params: cond})
          .then((val) => {
            _this.infosData = [];
            _this.handelSlugData(val.data, 2);
            _this.showToastMask(false);
            _this.isLoading = true
          }).catch((err) => {
        _this.isLoading = true
        _this.showToastMask(false);
      });
    },

    /************************************************************
     *
     * 处理书籍分类和文化分类的数据格式
     *
     ************************************************************/
    handelSlugData(data, label) {
      const title = label
      data.map((el, ind) => {
        el.titlelang = (el.titlelang.zh ? `<div class="div aqt_ce_tb contentfont" style="width:calc(100% - ${title}px);margin-left:${title}px;"> ${el.titlelang.zh}</div>` : `<div class="div aqt_ce_tb contentfont" style="width:calc(100% - ${title}px);margin-left:${title}px;"> ${el.titlelang.mn}</div>`)
        this.infosData.push(el)
        let label = title + 18
        if (el.child.length !== 0) this.handelSlugData(el.child, label)
      })
      localStorage.setItem("youmuBook_index", JSON.stringify(this.infosData));
    },
    getBookFromLocal() {
      this.infosData = JSON.parse(localStorage.getItem("youmuBook_index"));
      this.isLoading = true;
    },
    /*****************************************************************
     *
     * 获取详情数据
     *
     *****************************************************************/
    getPageList(item, index) {
      const _this = this;
      if (!JSON.parse(localStorage.getItem("userInfoMemberId"))) {
        _this.$router.push({
          path: "/login",
          query: {
            lastPath: _this.$route.fullPath,
          },
        });
        return;
      }
      _this.showToastMask(true);
      let cond = {
        directory_id: item.directory_id,
        book_id: item.book_id,
        type: 1
      }
      _this.$http.get("api/web/v1/BookContent/index", {params: cond})
          .then((val) => {
            localStorage.setItem("book_pages", JSON.stringify(val.data));
            _this.indexList = val.data;
            if (!_this.content_id && _this.indexList.length) {
              _this.content_id = _this.indexList[0].content_id;
            }
            if (val.data.length) {
              _this.$router.push({
                path: "/LiteratureContent",
                query: {
                  content_id: _this.content_id,
                  pdfurl: item.original,
                },
              });
            } else {
              if (item.original) {
                _this.$router.push({
                  path: "/zhPdfView",
                  query: {
                    url: item.original,
                  },
                });
              }
            }
            sessionStorage.setItem('pdfOpen', true)
            _this.showToastMask(false);
          }).catch((err) => {
        _this.showToastMask(false);
      });
    }
  },

  created() {
    if (localStorage.getItem("youmuBook_index")) {
      this.getBookFromLocal();
    } else {
      this.showToastMask(true);
      setTimeout(() => {
        if (!this.getInfo) {
          this.infoData()
        }
      }, 1000)
    }
    let have_Book = this.$store.state.books.some((item, index) => {
      return item.book_id == this.$route.query.id;
    });
    let index = this.$store.state.books.findIndex((item) => {
      if (item.book_id == this.$route.query.id) {
        return true;
      }
    });
    if (have_Book) {
      this.content_id = this.$store.state.books[index].content_id;
    }
  },
  computed: {
    // 是否显示资源
    annexboolean() {
      return this.$store.getters.annexboolean
    },
  },
  watch: {
    '$store.getters.annexboolean': function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.getInfo = true
        this.infoData();
      }
    },
  },
};
</script>

<style lang="less">
#books_index_zh {
  width: 100%;
  min-height: 100%;

  .muludiv {
    width: 100%;
    min-height: 100%;
    padding: 0 5px;
    background-color: #ffffff;

    .aqt_zhengzhuang {
      width: 100%;
      height: calc(10% - 3px);
      color: #333;
      font-size: 16px;
      margin: 3px 0 0;
      display: flex;
      border-bottom: 1px solid #ededed;

      .div {
        width: calc(100% - 44px);
        padding: 10px 0;
        overflow: hidden;
        color: #888888;
      }
    }
  }
}
</style>
